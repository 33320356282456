const PRODUCTS_TITLES = [
  {
    title: "קורקינט חשמלי Rider דגם M2 Pro",
    model: "Rider m2 Pro",
  },
  {
    title: "קורקינט חשמלי 36V 10A דגם City Runner Sport",
    model: "City Runner Sport",
  },
  {
    title: "קורקינט חשמלי 48V 22A דגם City Runner Apollo",
    model: "Runner Apollo",
  },
  {
    title: "קורקינט חשמלי 48V 13A City Runner 2 הדגם המשודרג!",
    model: "City Runner 2",
  },
  {
    title: "קורקינט חשמלי 48V 18A City Runner X2 הדגם המשודרג!",
    model: "City Runner X2",
  },
  {
    title: "קורקינט חשמלי 48V 23A City Runner X3 הדגם המשודרג!",
    model: "City Runner X3",
  },
  {
    title: "קורקינט חשמלי 48V 13A דגם City Runner Z",
    model: "City Runner Z",
  },
  {
    title: "קורקינט חשמלי 48V 23A דגם City Runner Phoenix",
    model: "Runner Phoenix",
  },
  {
    title: "קורקינט חשמלי 48V 18A Kaabo דגם Mantis 10R",
    model: "Mantis 10R",
  },
  {
    title: "אופניים חשמליים STARK 48V 10A דגם Z200",
    model: "Stark Z200",
  },
  {
    title: "אופניים חשמליים STARK 48V 13A דגם Z250",
    model: "Stark Z250",
  },
  {
    title: "אופניים חשמליים Stark 48V 13A דגם Z250 Rider",
    model: "Stark Z250 Rider 13A",
  },
  {
    title: "אופניים חשמליים Stark 48V 16A דגם Z250 Rider",
    model: "Stark Z250 Rider 16A",
  },
  {
    title: "אופניים חשמליים STARK 48V 13A דגם MACH 3",
    model: "Stark MACH 3",
  },
  {
    title: "אופניים חשמליים Stark 48V 16A דגם Z250 Plus",
    model: "Stark Z250 Plus",
  },
  {
    title: "אופניים חשמליים STARK 48V 13A דגם APEX",
    model: "Stark APEX 1",
  },
  {
    title: "אופניים חשמליים STARK 48V 18A דגם MACH 3 Plus",
    model: "Stark MACH 3 Plus",
  },
  {
    title: "אופניים חשמליים STARK 48V 18A דגם MACH 3 Rider",
    model: "Stark MACH 3 Rider",
  },
  {
    title: "אופניים חשמליים STARK 48V 16A דגם 2 APEX",
    model: "Stark Apex 2",
  },
  {
    title: "אופניים חשמליים STARK 48V 18A דגם MACH 5",
    model: "Stark MACH 5",
  },
  {
    title: "אופניים חשמליים STARK 48V 18A דגם APEX DH",
    model: "Stark Apex DH",
  },
  {
    title: " אופניים חשמליים STARK 48V 20A דגם SONIC",
    model: "Stark Sonic",
  },
  {
    title: "אופניים חשמליים Cycoo 36V 8A דגם Pixie",
    model: "Cycoo Pixie",
  },
  {
    title: "אופניים חשמליים 48V 14A דגם Alpha GT 2022",
    model: "Alpha GT",
  },
];
export default PRODUCTS_TITLES;
