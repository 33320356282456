import React, { useEffect, useState } from "react";

import Loader from "../Loader/Loader";
import "./prices.css";

function Prices() {
  const [modelsData, setModelsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdate, setLastUpdate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [models, setModels] = useState([]);
  const [filteredModels, setFilteredModels] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      getPrices();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const searchResults = models.filter(
      (model) => model.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
    );
    setFilteredModels(searchResults);
  }, [searchTerm, models]);

  const getPrices = async () => {
    const url = "/api/prices";
    try {
      const response = await fetch(url);
      const json = await response.json();
      const { modelsData, lastUpdate } = json;
      setModelsData(modelsData);
      setModels(Object.keys(modelsData));
      setFilteredModels(Object.keys(modelsData));
      setLastUpdate(lastUpdate);
    } catch (error) {
      console.log("error", error);
    }
  };

  const renderProfit = (model, site) => {
    const { price: korkiPrice = 0 } =
      modelsData[model].prices?.find(({ domain }) => domain === site) || {};
    const buyPrice = modelsData[model].buyPrice;
    return (
      <div className="profit">
        <div className="ilsProfit">Profit: {korkiPrice - buyPrice}</div>
        <div className="percentProfit">
          {Math.trunc(((korkiPrice - buyPrice) * 100) / korkiPrice)}%
        </div>
      </div>
    );
  };

  const isPriceTooLow = (price, minPrice) => price < minPrice;

  const renderCard = (model) => (
    <div className="card">
      <div className="pricesData">
        <div className="minPrice">min price: {modelsData[model].minPrice}</div>
        <div className="buyPrice">buy price: {modelsData[model].buyPrice}</div>
      </div>

      {renderProfit(model, "korkifix")}
      <div className="title">{model}</div>
      {modelsData[model].prices.map(({ domain, price, url }) =>
        !price ? (
          <></>
        ) : (
          <div className={`domainPrice ${domain}`}>
            <div className="domain">{domain}</div>
            <div
              className={`price ${
                isPriceTooLow(price, modelsData[model].minPrice) ? "tooLow" : ""
              }`}
            >
              {price}
            </div>
            <a className="link" href={url} target="_blank">
              Link
            </a>
          </div>
        )
      )}
    </div>
  );

  const updatePrices = async () => {
    try {
      setIsLoading(true);
      await fetch("/api/prices/scrape");
      await getPrices();
      setIsLoading(false);
    } catch (error) {
      console.log("{error: error.message}");
    }
  };

  return (
    <div>
      <h1>Korkifix Prices</h1>
      <h3>{lastUpdate}</h3>
      <button onClick={() => updatePrices()} disabled={isLoading}>
        {isLoading ? "Loading..." : "update"}
      </button>
      <div className="searchInput">
        <input
          name="searchTerm"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search for model"
        />
      </div>
      <div className="cards">
        {isLoading ? (
          <Loader />
        ) : (
          filteredModels.map((model) => renderCard(model))
        )}
      </div>
    </div>
  );
}

export default Prices;
