import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Prices from "./components/Prices/Prices";
import Review from "./components/review/Review";

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <div className="navigator">
          <Link className="buttonLinks" to="/prices">
            Prices tool
          </Link>
          <Link className="buttonLinks" to="/reviews">
            Reviews tool
          </Link>
        </div>
        <Routes>
          <Route path="/" element={<></>} />
          <Route path="/reviews" element={<Review />} />
          <Route path="/prices" element={<Prices />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
