import React, { useState } from "react";
import PRODUCTS_TITLES from "../../constants/productsTitles";
import Modal from "react-modal";
import "./review.css";

function Review() {
  const [chosenModel, setChosenModel] = useState(PRODUCTS_TITLES[0].title);
  const [chosenInput, setChosenInput] = useState("list");
  const [freeText, setFreeText] = useState("");
  const [invoice, setInvoice] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [link, setLink] = useState("");
  const [phone, setPhone] = useState("");

  const getLink = async () => {
    const base = `https://www.zap.co.il/ratestoreForm.aspx?siteid=12179`;
    const model = isList() ? chosenModel : freeText;
    const request = `/api/getBitly?base=${base}&model=${model}&invoice=${invoice}`;
    const data = await fetch(request);
    const json = await data.json();
    setLink(json.link);
  };

  const getMessage = () => (
    <div id="whatsappMessage">
      איזה כייף:), שמענו שקיבלת את ההזמנה!🎉
      <br />
      אם אהבת את השירות שלנו, נשמח אם תוכל לפרגן לנו בזאפ, זה עוזר לנו מאוד!❤️
      <br />
      הנה הלינק:
      <br />
      {link}
      <br />
      תודה רבה:) והכי חשוב סעו בזהירות!🛴🚲
    </div>
  );

  const CopyToClipboard = () => {
    let div = document.getElementById("whatsappMessage");
    let text = div.innerText;
    let textArea = document.createElement("textarea");
    textArea.width = "1px";
    textArea.height = "1px";
    textArea.background = "transparents";
    textArea.value = text;
    document.body.append(textArea);
    textArea.select();
    document.execCommand("copy"); //No i18n
    document.body.removeChild(textArea);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const sendMessage = () => {
    const phoneNumber = "972" + phone.substring(1);
    CopyToClipboard();
    window.open(`http://wa.me/${phoneNumber}`, "_blank");
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const isList = () => chosenInput === "list";

  return (
    <div className="form">
      <div className="reviewInput">
        <label>Model</label>
        <div>
          <select
            onChange={(e) => setChosenModel(e.target.value)}
            disabled={!isList()}
            value={chosenModel}
          >
            {PRODUCTS_TITLES.map(({ title, model }) => (
              <option key={model} value={title}>
                {model}
              </option>
            ))}
          </select>
          <input
            type="radio"
            name="model_radio"
            checked={isList()}
            value="list"
            onChange={(e) => setChosenInput(e.target.value)}
          />
        </div>
      </div>
      <div className="freeTextInput">
        <input
          value={freeText}
          onChange={(e) => setFreeText(e.target.value)}
          disabled={isList()}
        />
        <input
          type="radio"
          name="model_radio"
          checked={!isList()}
          value="freeText"
          onChange={(e) => setChosenInput(e.target.value)}
        />
      </div>
      <div className="reviewInput">
        <label>Invoice number</label>
        <input
          className="invoice"
          value={invoice}
          type="number"
          onChange={(e) => setInvoice(e.target.value)}
        />
      </div>

      <button
        disabled={!invoice}
        className="generateButton button"
        onClick={getLink}
      >
        generate
      </button>
      <div>
        {link && (
          <div>
            <div>{getMessage()}</div>
            <div className="messageActions">
              <button className="button" onClick={openModal}>
                Send Message
              </button>
              <button className="button" onClick={CopyToClipboard}>
                Copy Message
              </button>
            </div>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <h2>Set Phone</h2>
              <h3>You only need to paste it on Whatsapp (already copied):)</h3>
              <form className="sendMessageModal">
                <input
                  className="phone"
                  value={phone}
                  type="text"
                  onChange={(e) => setPhone(e.target.value)}
                />
                <button
                  disabled={!/^05\d([-]{0,1})\d{7}$/.test(phone)}
                  onClick={sendMessage}
                  className="button"
                >
                  Send
                </button>
                <button onClick={closeModal} className="button">
                  close
                </button>
              </form>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
}

export default Review;
